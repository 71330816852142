// Baugrundstücke
import Bau1 from './assets/images/Export Bilder/Baugrundstücke/Baugrundstück-Forckenbeckstrasse.jpg'
import Bau2 from './assets/images/Export Bilder/Baugrundstücke/Baugrundstück.Müggelsee.Vision.jpg'
import Bau3 from './assets/images/Export Bilder/Baugrundstücke/Baugrundstück.Pankow.Vision.jpg'
import Bau4 from './assets/images/Export Bilder/Baugrundstücke/Baugrundstück.Vision.jpg'

// Berlin

import Berlin from './assets/images/Export Bilder/Berlin/Adamstrasse IMG_8177.jpg'
import Berlin2 from './assets/images/Export Bilder/Berlin/Adolf-Mertens.jpg'
import Berlin3 from './assets/images/Export Bilder/Berlin/Albrechtstrasse.jpg'
import Berlin4 from './assets/images/Export Bilder/Berlin/Allee der kosmonauten.jpg'
import Berlin5 from './assets/images/Export Bilder/Berlin/Am Tierpark.jpg'
import Berlin6 from './assets/images/Export Bilder/Berlin/August-Bebel.jpg'
import Berlin7 from './assets/images/Export Bilder/Berlin/Beerenstrasse.jpg'
import Berlin8 from './assets/images/Export Bilder/Berlin/Behaimstrasse.jpg'
import Berlin9 from './assets/images/Export Bilder/Berlin/Carl-Heinrich-Becker.Pfegeheim.jpg'
import Berlin11 from './assets/images/Export Bilder/Berlin/Caspar-Theiss-Strasse.jpg'
import Berlin12 from './assets/images/Export Bilder/Berlin/Courbierstrasse.jpg'
import Berlin13 from './assets/images/Export Bilder/Berlin/Danziger.jpg'
import Berlin14 from './assets/images/Export Bilder/Berlin/Dietzgenstrasse.jpg'
import Berlin15 from './assets/images/Export Bilder/Berlin/Düsterhauptstrasse.jpg'
import Berlin16 from './assets/images/Export Bilder/Berlin/Einsteinufer.jpg'
import Berlin17 from './assets/images/Export Bilder/Berlin/Eisenzahnstrasse.jpg'
import Berlin18 from './assets/images/Export Bilder/Berlin/Elcknerplatz.jpg'
import Berlin19 from './assets/images/Export Bilder/Berlin/Fischerhüttenstrasse.jpg'
import Berlin20 from './assets/images/Export Bilder/Berlin/Flankenschanze.Spandau.jpg'
import Berlin21 from './assets/images/Export Bilder/Berlin/Friedrichstrasse-Spandau.jpg'
import Berlin22 from './assets/images/Export Bilder/Berlin/Gottlieb.Dunkel.Strasse.jpg'
import Berlin23 from './assets/images/Export Bilder/Berlin/Greta.Garbor.jpg'
import Berlin24 from './assets/images/Export Bilder/Berlin/Malplaquetstrasse.jpg'
import Berlin25 from './assets/images/Export Bilder/Berlin/Marchwitzer.Wohnanlage.jpg'
import Berlin26 from './assets/images/Export Bilder/Berlin/Maybachufer.jpg'
import Berlin27 from './assets/images/Export Bilder/Berlin/Nestorstraße.jpg'
import Berlin28 from './assets/images/Export Bilder/Berlin/Perleberger-Strasse.Tiergarten.jpg'
import Berlin29 from './assets/images/Export Bilder/Berlin/Pflegeeinrichtung.jpg'
import Berlin30 from './assets/images/Export Bilder/Berlin/Punkthaus-Marchwitza.jpg'
import Berlin31 from './assets/images/Export Bilder/Berlin/Puschkinallee.jpg'
import Berlin32 from './assets/images/Export Bilder/Berlin/Sonnenallee-Neukölln.jpg'
import Berlin33 from './assets/images/Export Bilder/Berlin/Spanische-Allee.Pflegeheim.jpg'
import Berlin34 from './assets/images/Export Bilder/Berlin/Symeonstrasse.jpg'
import Berlin35 from './assets/images/Export Bilder/Berlin/Wohnanlage.Maximilian-Kaller.jpg'

// Brandenburg

import Brandenburg from './assets/images/Export Bilder/Brandenburg/Am Mahlbusen.Brandenburg.jpg'
import Brandenburg1 from './assets/images/Export Bilder/Brandenburg/An der Viehtrift.Brandenburg.jpg'
import Brandenburg2 from './assets/images/Export Bilder/Brandenburg/Bahnhofstrasse.Brandenburg.jpg'
import Brandenburg3 from './assets/images/Export Bilder/Brandenburg/Center.Angermünde.jpg'
import Brandenburg4 from './assets/images/Export Bilder/Brandenburg/Center.Brandenburg.jpg'
import Brandenburg5 from './assets/images/Export Bilder/Brandenburg/Efeuring.Brandenburg.jpg'
import Brandenburg6 from './assets/images/Export Bilder/Brandenburg/Jahnstrasse-Hoppegarten.Brandenburg.jpg'
import Brandenburg7 from './assets/images/Export Bilder/Brandenburg/Postamt-Eberswalde.jpg'
import Brandenburg8 from './assets/images/Export Bilder/Brandenburg/Trebbiner.Strasse-Luckenwalde.jpg'
import Brandenburg9 from './assets/images/Export Bilder/Brandenburg/Wohnanlage-Angermünde.jpg'
import Brandenburg10 from './assets/images/Export Bilder/Brandenburg/Wohnanlage-Fürstenwalde.jpg'
import Brandenburg11 from './assets/images/Export Bilder/Brandenburg/Wohnanlage.Wittenberg.jpg'
import Brandenburg12 from './assets/images/Export Bilder/Brandenburg/Lindow.jpg'

// Bundesweit

import Bund from './assets/images/Export Bilder/Bundesweit/Burg.II.Magdeburg.jpg'
import Bund1 from './assets/images/Export Bilder/Bundesweit/Burg.III.Magdeburg.jpg'
import Bund2 from './assets/images/Export Bilder/Bundesweit/Burg.Magdeburg.jpg'
import Bund3 from './assets/images/Export Bilder/Bundesweit/Center-Wohnen-Perleberg.jpg'
import Bund4 from './assets/images/Export Bilder/Bundesweit/Center.Kassel.jpg'
import Bund5 from './assets/images/Export Bilder/Bundesweit/Cölpin.Mecklenburg-Vorpommern.jpg'
import Bund6 from './assets/images/Export Bilder/Bundesweit/Dessau.jpg'
import Bund7 from './assets/images/Export Bilder/Bundesweit/Einkaufscenter.Hoyerswerder.jpg'
import Bund8 from './assets/images/Export Bilder/Bundesweit/FrankfurtO.jpg'
import Bund9 from './assets/images/Export Bilder/Bundesweit/Logistic-Center.Hamm.jpg'
import Bund10 from './assets/images/Export Bilder/Bundesweit/Markt.Leipzig.jpg'
import Bund11 from './assets/images/Export Bilder/Bundesweit/Mücheln.Leipzig.jpg'
import Bund12 from './assets/images/Export Bilder/Bundesweit/Neubrandenburg.jpg'
import Bund13 from './assets/images/Export Bilder/Bundesweit/Richard-Wagner.Halberstadt.jpg'
import Bund14 from './assets/images/Export Bilder/Bundesweit/Sachteleben.jpg'
import Bund15 from './assets/images/Export Bilder/Bundesweit/220126.erfurt.boyneburgerufer.png'

// Eigentumswohnung

import Eigentum from './assets/images/Export Bilder/Eigentumswohnungen/Eigentumswohnung-Scharnhorst.jpg'
import Eigentum1 from './assets/images/Export Bilder/Eigentumswohnungen/Eigentumswohnung-Wielandstrasse.jpg'
import Eigentum2 from './assets/images/Export Bilder/Eigentumswohnungen/Eigentumswohnung.Charlottenburg.jpg'
import Eigentum3 from './assets/images/Export Bilder/Eigentumswohnungen/Eigentumswohnung.Königsallee.jpg'
import Eigentum4 from './assets/images/Export Bilder/Eigentumswohnungen/Eigentumswohnung.Meineke.jpg'
import Eigentum5 from './assets/images/Export Bilder/Eigentumswohnungen/Eigentumswohnung.Mitte.jpg'
import Eigentum6 from './assets/images/Export Bilder/Eigentumswohnungen/Eigentumswohnung.Pankow.jpg'
import Eigentum7 from './assets/images/Export Bilder/Eigentumswohnungen/Eigentumswohnung.Prenzlauer Berg.jpg'
import Eigentum8 from './assets/images/Export Bilder/Eigentumswohnungen/Eigentumswohnung.Schmargendorf.jpg'
import Eigentum9 from './assets/images/Export Bilder/Eigentumswohnungen/Eigentumswohnung.Wilmersdorf.jpg'

export const imageData = [
  {
    title: 'Forckenbeckstraße',
    src: Bau1,
    category: 'Baugrundstück',
  },
  {
    title: 'Müggelsee',
    src: Bau2,
    category: 'Baugrundstück',
  },
  {
    title: 'Pankow',
    src: Bau3,
    category: 'Baugrundstück',
  },
  {
    title: 'Köpenick',
    src: Bau4,
    category: 'Baugrundstück',
  },
  {
    title: 'Adamstraße',
    src: Berlin,
    category: 'Berlin',
  },
  {
    title: 'Adolf-Mertens-Straße',
    src: Berlin2,
    category: 'Berlin',
  },
  {
    title: 'Albrechtstraße',
    src: Berlin3,
    category: 'Berlin',
  },
  {
    title: 'Allee der Kosmonauten',
    src: Berlin4,
    category: 'Berlin',
  },
  {
    title: 'Am Tierpark',
    src: Berlin5,
    category: 'Berlin',
  },
  {
    title: 'August-Bebel-Straße',
    src: Berlin6,
    category: 'Berlin',
  },
  {
    title: 'Beerenstraße',
    src: Berlin7,
    category: 'Berlin',
  },
  {
    title: 'Behaimstraße',
    src: Berlin8,
    category: 'Berlin',
  },
  {
    title: 'Carl-Heinrich-Becker-Pflegeheim',
    src: Berlin9,
    category: 'Berlin',
  },

  {
    title: 'Caspar-Theiss-Straße',
    src: Berlin11,
    category: 'Berlin',
  },
  {
    title: 'Courbierstraße',
    src: Berlin12,
    category: 'Berlin',
  },
  {
    title: 'Danziger Straße',
    src: Berlin13,
    category: 'Berlin',
  },
  {
    title: 'Dietzgenstraße',
    src: Berlin14,
    category: 'Berlin',
  },
  {
    title: 'Düsterhauptstraße',
    src: Berlin15,
    category: 'Berlin',
  },
  {
    title: 'Einsteinufer',
    src: Berlin16,
    category: 'Berlin',
  },
  {
    title: 'Eisenzahnstraße',
    src: Berlin17,
    category: 'Berlin',
  },
  {
    title: 'Elcknerplatz',
    src: Berlin18,
    category: 'Berlin',
  },
  {
    title: 'Fischerhüttenstraße',
    src: Berlin19,
    category: 'Berlin',
  },
  {
    title: 'Flankenschanze Spandau',
    src: Berlin20,
    category: 'Berlin',
  },
  {
    title: 'Friedrichstraße Spandau',
    src: Berlin21,
    category: 'Berlin',
  },
  {
    title: 'Gottlieb-Dunkel-Straße',
    src: Berlin22,
    category: 'Berlin',
  },
  {
    title: 'Greta-Garbor-Straße',
    src: Berlin23,
    category: 'Berlin',
  },
  {
    title: 'Malplaquetstraße',
    src: Berlin24,
    category: 'Berlin',
  },
  {
    title: 'Marchwitzer Wohnanlange',
    src: Berlin25,
    category: 'Berlin',
  },
  {
    title: 'Maybachufer',
    src: Berlin26,
    category: 'Berlin',
  },
  {
    title: 'Nestorstraße',
    src: Berlin27,
    category: 'Berlin',
  },
  {
    title: 'Perleberger-Straße Tiergarten',
    src: Berlin28,
    category: 'Berlin',
  },
  {
    title: 'Pflegeeinrichtung',
    src: Berlin29,
    category: 'Berlin',
  },
  {
    title: 'Punkthaus-Marchwitza',
    src: Berlin30,
    category: 'Berlin',
  },
  {
    title: 'Puschkinallee',
    src: Berlin31,
    category: 'Berlin',
  },
  {
    title: 'Sonnenallee Neukölln',
    src: Berlin32,
    category: 'Berlin',
  },
  {
    title: 'Spanische-Allee Pflegeheim',
    src: Berlin33,
    category: 'Berlin',
  },
  {
    title: 'Symeonstraße',
    src: Berlin34,
    category: 'Berlin',
  },
  {
    title: 'Maximilian-Kaller-Straße Wohnanlage',
    src: Berlin35,
    category: 'Berlin',
  },
  {
    title: 'Am Mahlbusen',
    src: Brandenburg,
    category: 'Brandenburg',
  },
  {
    title: 'An der Viehtrift',
    src: Brandenburg1,
    category: 'Brandenburg',
  },
  {
    title: 'Bahnhofstraße',
    src: Brandenburg2,
    category: 'Brandenburg',
  },
  {
    title: 'Angermünde Center',
    src: Brandenburg3,
    category: 'Brandenburg',
  },
  {
    title: 'Brandenburg Center',
    src: Brandenburg4,
    category: 'Brandenburg',
  },
  {
    title: 'Efeuring',
    src: Brandenburg5,
    category: 'Brandenburg',
  },
  {
    title: 'Jahnstraße Hoppegarten',
    src: Brandenburg6,
    category: 'Brandenburg',
  },
  {
    title: 'Postamt Eberswalde',
    src: Brandenburg7,
    category: 'Brandenburg',
  },
  {
    title: 'Trebbiner-Straße Luckenwalde',
    src: Brandenburg8,
    category: 'Brandenburg',
  },
  {
    title: 'Wohnanlage Angermünde',
    src: Brandenburg9,
    category: 'Brandenburg',
  },
  {
    title: 'Wohnanlage Fürstenwalde',
    src: Brandenburg10,
    category: 'Brandenburg',
  },
  {
    title: 'Wohnanlage Wittenberg',
    src: Brandenburg11,
    category: 'Brandenburg',
  },
  {
    title: 'Wohnensemble Lindow',
    src: Brandenburg12,
    category: 'Brandenburg',
  },
  {
    title: 'Burg Magdeburg',
    src: Bund,
    category: 'Bundesweit',
  },
  {
    title: 'Burg Magdeburg',
    src: Bund1,
    category: 'Bundesweit',
  },
  {
    title: 'Burg Magdeburg',
    src: Bund2,
    category: 'Bundesweit',
  },
  {
    title: 'Center-Wohnen Perleberg',
    src: Bund3,
    category: 'Bundesweit',
  },
  {
    title: 'Center Kassel',
    src: Bund4,
    category: 'Bundesweit',
  },
  {
    title: 'Cölpin Mecklenburg-Vorpommern',
    src: Bund5,
    category: 'Bundesweit',
  },
  {
    title: 'Dessau',
    src: Bund6,
    category: 'Bundesweit',
  },
  {
    title: 'Einkaufscenter Hoyerswerder',
    src: Bund7,
    category: 'Bundesweit',
  },
  {
    title: 'Frankfurt Oder',
    src: Bund8,
    category: 'Bundesweit',
  },
  {
    title: 'Logitic-Center Hamm',
    src: Bund9,
    category: 'Bundesweit',
  },
  {
    title: 'Markt Leipzig',
    src: Bund10,
    category: 'Bundesweit',
  },
  {
    title: 'Mücheln Leipzig',
    src: Bund11,
    category: 'Bundesweit',
  },
  {
    title: 'Neubrandenburg',
    src: Bund12,
    category: 'Bundesweit',
  },
  {
    title: 'Richard-Wagner-Straße Halberstadt',
    src: Bund13,
    category: 'Bundesweit',
  },
  {
    title: 'Sachteleben',
    src: Bund14,
    category: 'Bundesweit',
  },
  {
    title: 'Erfurt',
    src: Bund15,
    category: 'Bundesweit',
  },
  {
    title: 'Scharnhorst',
    src: Eigentum,
    category: 'Eigentumswohnung',
  },
  {
    title: 'Wielandstraße',
    src: Eigentum1,
    category: 'Eigentumswohnung',
  },
  {
    title: 'Charlottenburg',
    src: Eigentum2,
    category: 'Eigentumswohnung',
  },
  {
    title: 'Königsallee',
    src: Eigentum3,
    category: 'Eigentumswohnung',
  },
  {
    title: 'Meineke',
    src: Eigentum4,
    category: 'Eigentumswohnung',
  },
  {
    title: 'Mitte',
    src: Eigentum5,
    category: 'Eigentumswohnung',
  },
  {
    title: 'Pankow',
    src: Eigentum6,
    category: 'Eigentumswohnung',
  },
  {
    title: 'Prenzlauer Berg',
    src: Eigentum7,
    category: 'Eigentumswohnung',
  },
  {
    title: 'Schmargendorf',
    src: Eigentum8,
    category: 'Eigentumswohnung',
  },
  {
    title: 'Wilmersdorf',
    src: Eigentum9,
    category: 'Eigentumswohnung',
  },
]
