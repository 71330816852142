import { imageData } from './images'
import '@material-tailwind/react/tailwind.css'
import { useState } from 'react'
import Modal from '@material-tailwind/react/Modal'
import ModalBody from '@material-tailwind/react/ModalBody'
import Navbar from '@material-tailwind/react/Navbar'
import NavbarContainer from '@material-tailwind/react/NavbarContainer'
import NavbarWrapper from '@material-tailwind/react/NavbarWrapper'
import NavbarBrand from '@material-tailwind/react/NavbarBrand'
import NavbarToggler from '@material-tailwind/react/NavbarToggler'
import NavbarCollapse from '@material-tailwind/react/NavbarCollapse'
import Nav from '@material-tailwind/react/Nav'
import NavLink from '@material-tailwind/react/NavLink'
import VertrauenLogo from './assets/images/30_Jahre_Vertrauen.jpg'
import AboutUsImage from './assets/images/Export Bilder/Baugrundstücke/Baugrundstück-Forckenbeckstrasse.jpg'
import BerlinerFernsehtum from './assets/images/Kontur-BerlinerFernsehturm.png'
import KnowHow1 from './assets/images/Know-How2.jpg'
import AGB from './assets/AGB.25.04.12.pdf'
import ArrowImage from './assets/images/curve-arrow.png'

function App() {
  const [showModal, setShowModal] = useState(false)
  const [image, setImage] = useState('')
  const [imageAlt, setImageAlt] = useState('')
  const [openMenu, setOpenMenu] = useState(false)

  const ImageModal = () => {
    return (
      <Modal size='md' active={showModal} toggler={() => setShowModal(false)}>
        <ModalBody>
          <img
            src={image}
            alt={imageAlt}
            className='inset-0 rounded-lg object-cover p-0'
          />
        </ModalBody>
      </Modal>
    )
  }

  const Home = () => {
    return (
      <div className='m-0 p-0'>
        <div className='invisible absolute flex md:visible w-auto items-center justify-center align-middle top-96 -mt-6 right-1/3 left-1/3 transform  h-auto  opacity-10 -z-20'>
          <img src={BerlinerFernsehtum} alt='Fernsehturm' />
        </div>
        <img
          src={VertrauenLogo}
          alt='30 Jahre vertrauen'
          className='absolute invisible lg:visible w-32 h-32  top-[158px] left-[60px]'
        />
        <div className='p-2 md:p-10'>
          <div className='text-center items-center flex justify-center flex-col my-20'>
            <div className='w-28 md:w-36'>
              <svg
                height='100%'
                viewBox='0 0 234 161'
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                style={{
                  fillRule: 'evenodd',
                  clipRule: 'evenodd',
                  strokeMiterlimit: 2,
                  marginBottom: 10,
                }}
              >
                <g transform='matrix(1,0,0,1,-1143.62,-1092.85)'>
                  <g transform='matrix(7.5975,0,0,7.5975,-1054.1,655.249)'>
                    <path
                      d='M298.865,78.281L289.576,78.281L289.576,77.624C289.576,77.624 290.759,77.93 291.722,76.134L291.853,60.578C291.853,60.578 291.548,59.087 289.75,58.562L289.75,57.905L300.574,57.993L304.518,69.649L308.549,58.08L319.503,58.08L319.46,58.606C319.46,58.606 318.101,59 317.269,60.139L317.224,75.783C317.224,75.783 317.882,77.843 319.591,77.886L319.636,78.457L310.082,78.413L310.082,77.667C310.082,77.667 311.66,77.798 312.537,75.872L312.406,61.367L305.613,78.238L303.159,78.194L296.63,61.016L296.63,76.002C296.63,76.002 297.069,77.667 298.821,77.667L298.865,78.281Z'
                      style={{
                        fill: 'none',
                        stroke: 'rgb(239, 58, 66)',
                        strokeWidth: '0.27px',
                      }}
                    />
                  </g>
                  <g transform='matrix(7.5975,0,0,7.5975,-1054.1,655.249)'>
                    <path
                      d='M308.241,64.717L308.355,69.122L307.961,69.122C307.772,68.019 307.308,67.133 306.57,66.462C305.83,65.792 305.031,65.457 304.171,65.457C303.507,65.457 302.981,65.634 302.593,65.988C302.205,66.342 302.013,66.75 302.013,67.209C302.013,67.502 302.08,67.762 302.218,67.989C302.406,68.294 302.709,68.596 303.125,68.895C303.43,69.108 304.137,69.489 305.244,70.034C306.792,70.793 307.837,71.512 308.376,72.187C308.91,72.864 309.177,73.636 309.177,74.507C309.177,75.613 308.745,76.563 307.88,77.36C307.017,78.156 305.919,78.553 304.59,78.553C304.172,78.553 303.777,78.511 303.407,78.426C303.035,78.34 302.569,78.179 302.008,77.946C301.695,77.814 301.437,77.749 301.234,77.749C301.065,77.749 300.886,77.815 300.698,77.948C300.509,78.08 300.355,78.282 300.238,78.553L299.893,78.553L299.893,73.557L300.238,73.557C300.525,74.958 301.078,76.025 301.896,76.76C302.715,77.497 303.598,77.863 304.544,77.863C305.275,77.863 305.857,77.666 306.291,77.269C306.725,76.874 306.942,76.413 306.942,75.886C306.942,75.575 306.859,75.273 306.694,74.981C306.527,74.688 306.274,74.411 305.937,74.148C305.599,73.885 304.999,73.542 304.14,73.122C302.937,72.53 302.072,72.027 301.546,71.611C301.019,71.195 300.616,70.73 300.332,70.216C300.051,69.703 299.909,69.137 299.909,68.52C299.909,67.467 300.295,66.57 301.067,65.829C301.841,65.087 302.814,64.717 303.991,64.717C304.419,64.717 304.834,64.769 305.238,64.873C305.541,64.951 305.914,65.095 306.352,65.305C306.791,65.515 307.097,65.621 307.273,65.621C307.441,65.621 307.576,65.569 307.671,65.466C307.769,65.362 307.86,65.113 307.945,64.717L308.241,64.717Z'
                      style={{ fill: 'rgb(239,58,66)', fillRule: 'nonzero' }}
                    />
                  </g>
                  <g transform='matrix(7.5975,0,0,7.5975,-1054.1,655.249)'>
                    <path
                      d='M308.241,64.717L308.355,69.122L307.961,69.122C307.772,68.019 307.308,67.133 306.57,66.462C305.83,65.792 305.031,65.457 304.171,65.457C303.507,65.457 302.981,65.634 302.593,65.988C302.205,66.342 302.013,66.75 302.013,67.209C302.013,67.502 302.08,67.762 302.218,67.989C302.406,68.294 302.709,68.596 303.125,68.895C303.43,69.108 304.137,69.489 305.244,70.034C306.792,70.793 307.837,71.512 308.376,72.187C308.91,72.864 309.177,73.636 309.177,74.507C309.177,75.613 308.745,76.563 307.88,77.36C307.017,78.156 305.919,78.553 304.59,78.553C304.172,78.553 303.777,78.511 303.407,78.426C303.035,78.34 302.569,78.179 302.008,77.946C301.695,77.814 301.437,77.749 301.234,77.749C301.065,77.749 300.886,77.815 300.698,77.948C300.509,78.08 300.355,78.282 300.238,78.553L299.893,78.553L299.893,73.557L300.238,73.557C300.525,74.958 301.078,76.025 301.896,76.76C302.715,77.497 303.598,77.863 304.544,77.863C305.275,77.863 305.857,77.666 306.291,77.269C306.725,76.874 306.942,76.413 306.942,75.886C306.942,75.575 306.859,75.273 306.694,74.981C306.527,74.688 306.274,74.411 305.937,74.148C305.599,73.885 304.999,73.542 304.14,73.122C302.937,72.53 302.072,72.027 301.546,71.611C301.019,71.195 300.616,70.73 300.332,70.216C300.051,69.703 299.909,69.137 299.909,68.52C299.909,67.467 300.295,66.57 301.067,65.829C301.841,65.087 302.814,64.717 303.991,64.717C304.419,64.717 304.834,64.769 305.238,64.873C305.541,64.951 305.914,65.095 306.352,65.305C306.791,65.515 307.097,65.621 307.273,65.621C307.441,65.621 307.576,65.569 307.671,65.466C307.769,65.362 307.86,65.113 307.945,64.717L308.241,64.717Z'
                      style={{ fill: 'rgb(239,58,66)', fillRule: 'nonzero' }}
                    />
                  </g>
                  <g transform='matrix(7.5975,0,0,7.5975,-1054.1,655.249)'>
                    <path
                      d='M308.241,64.717L308.355,69.122L307.961,69.122C307.772,68.019 307.308,67.133 306.57,66.462C305.83,65.792 305.031,65.457 304.171,65.457C303.507,65.457 302.981,65.634 302.593,65.988C302.205,66.342 302.013,66.75 302.013,67.209C302.013,67.502 302.08,67.762 302.218,67.989C302.406,68.294 302.709,68.596 303.125,68.895C303.43,69.108 304.137,69.489 305.244,70.034C306.792,70.793 307.837,71.512 308.376,72.187C308.91,72.864 309.177,73.636 309.177,74.507C309.177,75.613 308.745,76.563 307.88,77.36C307.017,78.156 305.919,78.553 304.59,78.553C304.172,78.553 303.777,78.511 303.407,78.426C303.035,78.34 302.569,78.179 302.008,77.946C301.695,77.814 301.437,77.749 301.234,77.749C301.065,77.749 300.886,77.815 300.698,77.948C300.509,78.08 300.355,78.282 300.238,78.553L299.893,78.553L299.893,73.557L300.238,73.557C300.525,74.958 301.078,76.025 301.896,76.76C302.715,77.497 303.598,77.863 304.544,77.863C305.275,77.863 305.857,77.666 306.291,77.269C306.725,76.874 306.942,76.413 306.942,75.886C306.942,75.575 306.859,75.273 306.694,74.981C306.527,74.688 306.274,74.411 305.937,74.148C305.599,73.885 304.999,73.542 304.14,73.122C302.937,72.53 302.072,72.027 301.546,71.611C301.019,71.195 300.616,70.73 300.332,70.216C300.051,69.703 299.909,69.137 299.909,68.52C299.909,67.467 300.295,66.57 301.067,65.829C301.841,65.087 302.814,64.717 303.991,64.717C304.419,64.717 304.834,64.769 305.238,64.873C305.541,64.951 305.914,65.095 306.352,65.305C306.791,65.515 307.097,65.621 307.273,65.621C307.441,65.621 307.576,65.569 307.671,65.466C307.769,65.362 307.86,65.113 307.945,64.717L308.241,64.717Z'
                      style={{
                        fill: 'none',
                        stroke: 'rgb(239,58,66)',
                        strokeWidth: '0.08px',
                      }}
                    />
                  </g>
                </g>
              </svg>
            </div>
            <p className='invisible md:visible font-light italic md:text-3xl text-gray-600 font-["Lora"]'>
              <strong>MICHAEL SCHOLTZ</strong> - <strong>IMMOBILIEN</strong> -{' '}
              <strong> ANLAGEVERMITTLUNG</strong>
            </p>
            <p className=' -mt-6 visible md:invisible font-light italic md:text-3xl text-gray-600 font-["Lora"]'>
              <strong>MICHAEL SCHOLTZ</strong>
              <br />
              <strong>IMMOBILIEN</strong> - <strong>ANLAGEVERMITTLUNG</strong>
            </p>
          </div>

          <div
            className='flex flex-col md:flex-row my-20 align-middle items-center justify-around scroll-mt-24'
            id='about'
          >
            <div className=' w-11/12 md:w-1/2  text-justify'>
              <h1 className='font-bold text-2xl py-2 text-gray-600 font-["Lora"]'>
                ÜBER UNS
              </h1>
              <p className='font-medium'>
                Die im Jahre 1992 gegründete Firma Michael Scholtz Immobilien
                und Anlagevermittlung, bietet umfassende Beratung,
                professionelles Management mit allen erforderlichen und
                notwendigen Leistungen, bei der Vermittlung von Investments. Wir
                sind stolz darauf, dass wir weit über 8.000 nationale und
                internationale Kunden, aus einem Kreis von
                Privat-Geschäftskunden und Institutionellen Investoren betreuen.
                Die Immobilie ist unumstritten einer der besten und sichersten
                Investitionen weltweit, nicht immer ohne Risiken, jedoch eine
                Investition in die Zukunft.
              </p>
            </div>
            <div className='flex flex-col h-96 w-11/12 md:w-1/3 '>
              <div className=' overflow-hidden flex flex-col h-80 rounded-xl  mt-2 bg-scholz_red-900'>
                <img
                  src={AboutUsImage}
                  alt='Forckenbeckstraße'
                  className='object-cover w-full h-full '
                />
              </div>
              <h1 className='mt-0  h-16 italic flex flex-row  text-center items-center font-normal font-["Oooh-Baby"]'>
                <img
                  src={ArrowImage}
                  alt='Arrow_Image_by_Kharisma_Flaticon'
                  className='h-8 w-8 mb-2'
                />
                ... ein Highlight unserer Arbeit!
              </h1>
            </div>
          </div>
          <div
            className='flex flex-col md:flex-row my-20 align-middle items-center justify-around scroll-mt-24'
            id='know-how'
          >
            <div className='w-11/12 md:w-1/3 h-80 overflow-hidden rounded-xl  mt-2 bg-scholz_red-900 order-last md:order-first'>
              <img
                src={KnowHow1}
                alt='Forckenbeckstraße'
                className='object-cover w-full h-full '
              />
            </div>
            <div className=' w-11/12 md:w-1/2  text-justify'>
              <h1 className='font-bold text-2xl py-2 text-gray-600 font-["Lora"]'>
                KNOW-HOW
              </h1>
              <p className='font-medium'>
                Unser umfassendes Netzwerk aus drei Jahrzehnten, mit modernster
                Kommunikations- Scan- und Fototechnik und vor allem unsere
                Erfahrung sind unser Potential. Wir bewerten Ihr Investment,
                erstellen perspektivische Analysen und Berechnen die
                Entwicklung, sodass wir einen höchstmöglichen Kaufpreis für
                unsere Auftrageber erziehlen können. Wir sind im Auftrag unserer
                Kunden unterwegs und beraten diese nach besten Wissen und
                Gewissen. Ungeachtet aller Erfahrung und Kompetenz bei der
                professionellen Vermarktung, Aufbereitung und Betreuung, werden
                auch spezielle Wünsche oder Strategien unserer Auftraggeber
                übernommen und in der jeweils gewünschten Form bei der
                Vermittlung von dem Investment berücksichtigt. Wir werden die
                uns anvertrauten Daten, Unterlagen und sonstigen Informationen
                streng vertraulich behandeln und nur an nachhaltig interessierte
                Kunden sowie berechtigte Personen reichen.
              </p>
            </div>
          </div>
          <div
            className='flex flex-row my-20 align-middle items-center justify-around scroll-mt-24'
            id='references'
          >
            <div className=' w-11/12 md:w-8/12  text-justify'>
              <h1 className='font-bold text-2xl py-2 text-gray-600 font-["Lora"] uppercase'>
                Unsere Referenzen
              </h1>
              <p className='font-medium'>
                Bei uns finden Sie Kapitalanlagen in allen Bereichen der
                Sachwertanlage. Wir vermitteln Wohnimmobilien, Wohnanlagen,
                Wohn- und Geschäftshäuser, Gewerbeobjekte, Spezialimmobilien,
                Pflegeheime, Hotels, Baugrundstücke, Sozialimmobilien,
                Einkaufscenter, Einfamilienhäuser und Eigentumswohnungen
                bundesweit, mit Standortanalysen und Zielkalkulationen.
              </p>
            </div>
          </div>

          <h1 className='text-center mt-10 font-semibold sm:text-3xl text-gray-600 font-["Lora"] uppercase'>
            Berlin
          </h1>
          <ul className='py-6  h-full  w-full flex overflow-x-auto gap-8 snap-x scrollbar scrollbar-thumb-scholz_red-900 scrollbar-track-gray-100'>
            {imageData.map((i) => {
              return i.category === 'Berlin' ? (
                <li
                  onClick={() => {
                    setShowModal(true)
                    setImage(i.src)
                    setImageAlt(i.title)
                  }}
                  className='snap-center'
                >
                  <div className='relative h-full cursor-zoom-in w-96 overflow-hidden rounded-3xl'>
                    <div className=' absolute inset-0 h-full bg-gradient-to-br from-black/50'></div>
                    <div className='absolute m-4'>
                      <p className=' z-10 font-medium text-white'>
                        {i.category}
                      </p>
                      <h2 className='z-50 w-2/3 text-3xl font-semibold  text-white'>
                        {i.title}
                      </h2>
                    </div>
                    <img
                      src={i.src}
                      alt={i.title}
                      className='snap-center inset-0 w-full h-full object-cover '
                    />
                  </div>
                </li>
              ) : (
                ''
              )
            })}
          </ul>
          <h1 className='text-center mt-10 font-semibold sm:text-3xl text-gray-600 font-["Lora"] uppercase'>
            Brandenburg
          </h1>
          <ul className='py-6  h-full  w-full flex overflow-x-auto gap-8 snap-x scrollbar scrollbar-thumb-scholz_red-900 scrollbar-track-gray-100'>
            {imageData.map((i) => {
              return i.category === 'Brandenburg' ? (
                <li
                  onClick={() => {
                    setShowModal(true)
                    setImage(i.src)
                    setImageAlt(i.title)
                  }}
                  className='snap-center'
                >
                  <div className='relative h-full cursor-zoom-in w-96 overflow-hidden rounded-3xl'>
                    <div className=' absolute inset-0 h-full bg-gradient-to-br from-black/50'></div>
                    <div className='absolute m-4'>
                      <p className=' z-10 font-medium text-white'>
                        {i.category}
                      </p>
                      <h2 className='z-50 w-2/3 text-3xl font-semibold  text-white'>
                        {i.title}
                      </h2>
                    </div>
                    <img
                      src={i.src}
                      alt={i.title}
                      className='snap-center inset-0 w-full h-full object-cover'
                    />
                  </div>
                </li>
              ) : (
                ''
              )
            })}
          </ul>

          <h1 className='text-center mt-10 font-semibold sm:text-3xl text-gray-600 font-["Lora"] uppercase'>
            Bundesweit
          </h1>
          <ul className='py-6  h-full  w-full flex overflow-x-auto gap-8 snap-x scrollbar scrollbar-thumb-scholz_red-900 scrollbar-track-gray-100'>
            {imageData.map((i) => {
              return i.category === 'Bundesweit' ? (
                <li
                  onClick={() => {
                    setShowModal(true)
                    setImage(i.src)
                    setImageAlt(i.title)
                  }}
                  className='snap-center'
                >
                  <div className='relative h-full cursor-zoom-in w-96 overflow-hidden rounded-3xl'>
                    <div className=' absolute inset-0 h-full bg-gradient-to-br from-black/50'></div>
                    <div className='absolute m-4'>
                      <p className=' z-10 font-medium text-white'>
                        {i.category}
                      </p>
                      <h2 className='z-50 w-2/3 text-3xl font-semibold  text-white'>
                        {i.title}
                      </h2>
                    </div>
                    <img
                      src={i.src}
                      alt={i.title}
                      className='snap-center inset-0 w-full h-full object-cover '
                    />
                  </div>
                </li>
              ) : (
                ''
              )
            })}
          </ul>
          <h1 className='text-center mt-10 font-semibold sm:text-3xl text-gray-600 font-["Lora"] uppercase'>
            Baugrundstücke
          </h1>
          <ul className='py-6  h-full  w-full flex overflow-x-auto gap-8 snap-x scrollbar scrollbar-thumb-scholz_red-900 scrollbar-track-gray-100'>
            {imageData.map((i) => {
              return i.category === 'Baugrundstück' ? (
                <li
                  onClick={() => {
                    setShowModal(true)
                    setImage(i.src)
                    setImageAlt(i.title)
                  }}
                  className='snap-center'
                >
                  <div className='relative h-full cursor-zoom-in w-96 overflow-hidden rounded-3xl'>
                    <div className=' absolute inset-0 h-full bg-gradient-to-br from-black/50'></div>
                    <div className='absolute m-4'>
                      <p className=' z-10 font-medium text-white'>
                        {i.category}
                      </p>
                      <h2 className='z-50 w-2/3 text-3xl font-semibold  text-white'>
                        {i.title}
                      </h2>
                    </div>
                    <img
                      src={i.src}
                      alt={i.title}
                      className='snap-center inset-0 w-full h-full object-cover '
                    />
                  </div>
                </li>
              ) : (
                ''
              )
            })}
          </ul>
          <h1 className='text-center mt-10 font-semibold  sm:text-3xl text-gray-600 font-["Lora"] uppercase'>
            Eigentumswohnungen
          </h1>
          <ul className='py-6  h-full   w-full flex overflow-x-auto gap-8 snap-x scrollbar scrollbar-thumb-scholz_red-900 scrollbar-track-gray-100'>
            {imageData.map((i) => {
              return i.category === 'Eigentumswohnung' ? (
                <li
                  onClick={() => {
                    setShowModal(true)
                    setImage(i.src)
                    setImageAlt(i.title)
                  }}
                  className='snap-center'
                >
                  <div className='relative h-full cursor-zoom-in w-96 overflow-hidden rounded-3xl'>
                    <div className=' absolute inset-0 h-full bg-gradient-to-br from-black/50'></div>
                    <div className='absolute m-4'>
                      <p className=' z-10 font-medium text-white'>
                        {i.category}
                      </p>
                      <h2 className='z-50 w-2/3 text-3xl font-semibold  text-white'>
                        {i.title}
                      </h2>
                    </div>
                    <img
                      src={i.src}
                      alt={i.title}
                      className='snap-center inset-0 w-full h-full object-cover '
                    />
                  </div>
                </li>
              ) : (
                ''
              )
            })}
          </ul>
        </div>
        <div className=' h-16 w-full bg-scholz_red-900 items-center py-2 px-12 flex flex-col md:flex-row justify-between text-center align-middle'>
          <h1 className='text-white'>
            © {new Date().getFullYear()} Scholtz Immobilien
          </h1>
          <div className='flex flex-row justify-between '>
            <p className='text-white px-2'>
              <a href='/impressum.html'>Impressum</a>
            </p>
            <p className='text-white px-2'>
              <a href='/datenschutz.html'>Datenschutz</a>
            </p>
            <p className='text-white px-2'>
              <a href={AGB}>AGB</a>
            </p>
          </div>
        </div>
      </div>
    )
  }

  const NavBarTop = () => {
    return (
      <div className='bg-scholz_red-900 sticky top-0 z-50 mb-0'>
        <Navbar color='' navbar>
          <NavbarContainer>
            <NavbarWrapper>
              <NavbarBrand>
                <a href='/'>MS-Immobilien</a>
              </NavbarBrand>
              <NavbarToggler
                color='white'
                onClick={() => setOpenMenu(!openMenu)}
                ripple='light'
              />
            </NavbarWrapper>

            <NavbarCollapse open={openMenu}>
              <Nav>
                <NavLink href='#about' ripple='light'>
                  Über uns
                </NavLink>
                <NavLink href='#know-how' ripple='light'>
                  Know-how
                </NavLink>
                <NavLink href='#references' ripple='light'>
                  Referenzen
                </NavLink>
              </Nav>
            </NavbarCollapse>
          </NavbarContainer>
        </Navbar>
      </div>
    )
  }

  return (
    <div className='scroll-smooth'>
      <NavBarTop />
      <ImageModal />
      <Home />
    </div>
  )
}

export default App
